import { createContext, useContext, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { RoutePath } from "../../common/router/route-path";
import { useUserManager } from "../../manager/user-manager";
import { UserModel } from "../../models/user-model";
import { useCookies } from "../../manager/use-cookies";


const AuthContext = createContext<AuthType>(null!)

interface AuthType {

}

export function useAuthProvider() {
    return useContext(AuthContext)
}


export function AuthProvider() {
    const userManager = useUserManager()
    const user = userManager.get()
    const value = {

    }
    return (
        <AuthContext.Provider value={value}>
            {
                !user
                    ? <Navigate to={RoutePath.login} replace />
                    : <Outlet />
            }
        </AuthContext.Provider>
    )
}