import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { DrawerModel } from '../../../models/drawer-model';
import { RoutePath } from '../../router/route-path';
import ItemDrawer from './item-drawer';
import UserButton from './widgets/user-button';
import { useMyTheme } from '../../theme/use-my-theme';
import { useMyMediaQuery } from '../../theme/use-my-media-query';
import Logo from '../../../assets/logo.png';

export const drawerWidth = 240;

interface Props {
}

export default function ResponsiveDrawer(props: Props) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useMyTheme()
  const { isMobile } = useMyMediaQuery()
  const [items] = useState<Array<DrawerModel>>([
    DrawerModel.fromJson({
      title: 'Dashboard',
      path: RoutePath.initial,
    }),
    DrawerModel.fromJson({
      title: 'Orders',
      path: RoutePath.order,
    }),

    DrawerModel.fromJson({
      title: 'Card Categories',
      path: RoutePath.cardCategory,
    }),
    DrawerModel.fromJson({
      title: 'Menu',
      path: RoutePath.menu,
    }),
    DrawerModel.fromJson({
      title: 'Cards',
      path: RoutePath.card,
    }),
    DrawerModel.fromJson({
      title: 'Bank',
      path: RoutePath.bank,
    }),
    DrawerModel.fromJson({
      title: 'Payment Method',
      path: RoutePath.paymentMethod,
    }),
    DrawerModel.fromJson({
      title: 'Article',
      path: RoutePath.article,
    }),
    DrawerModel.fromJson({
      title: 'Users',
      path: RoutePath.user,
    }),


  ])

  const handleDrawerToggle = () => {
    if (!isMobile) return
    setMobileOpen(!mobileOpen);
  }

  const drawer = (
    <Box
      minHeight={'100vh'}
      bgcolor={theme.palette.background.default}>
      <Toolbar />

      {
        items.map((e) => (
          <ItemDrawer
            onCloseDrawer={handleDrawerToggle}
            key={e.title}
            item={e} />
        ))
      }
    </Box>
  );

  return (
    <Box>
      <AppBar
        position="fixed"
        sx={{
          bgcolor: 'transparent',
          boxShadow: 'none'
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <img src={Logo} alt="" width={48} style={{ display: isMobile ? 'none' : ' ' }} />
          <Typography
            color={theme.palette.primary.main}
            variant="h6"
            ml={2}
            flexGrow={1}>
            Card Admin
          </Typography>
          <UserButton />
        </Toolbar>
      </AppBar>
      <Box>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Box
          component="nav"
          position={'fixed'}
          sx={{
            display: { xs: 'none', sm: 'block' },
            width: { sm: drawerWidth },
            flexShrink: { sm: 0 }
          }}
        >
          {drawer}
        </Box>
        <Box display={'flex'}>
          <Box
            display={{ xs: 'none', sm: 'block' }}
            width={drawerWidth} />
          <Box
            sx={{
              flexGrow: 1,
              pl: { xs: 0, sm: 2 },
              pr: { xs: 0, sm: 2 }
            }}
          >
            <Toolbar />
            <Outlet />
          </Box>
        </Box>

      </Box>

    </Box>
  );
}
