import { MoreHorizOutlined } from "@mui/icons-material";
import { Box, IconButton, Menu, MenuItem, Typography, alpha } from "@mui/material";
import { ReactNode, useState } from "react";
import { useMyTheme } from "../theme/use-my-theme";

export class OptionModel {
	id?: number
	child?: ReactNode
	icon?: ReactNode
	title?: string
	color?: string
	onPressed?: VoidFunction
	static from(json?: OptionModel) {
		return Object.assign(new OptionModel(), json)
	}
}

interface ButtonMoreType {
	options?: Array<OptionModel>
}

const ButtonMore = (props: ButtonMoreType) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const theme = useMyTheme()
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation()
		setAnchorEl(event.currentTarget);
	}

	function _onClickItem(e: OptionModel): void {
		e.onPressed && e.onPressed!()
	}

	function _onClose() {
		setAnchorEl(null)
	}

	return (
		<Box>
			<IconButton
				onClick={handleClick}>
				<MoreHorizOutlined sx={{
					color: alpha(theme.palette.common.black, 0.5)
				}} />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={_onClose}>
				{
					props.options?.map((e, i) => (
						<MenuItem
							sx={{
								px: 4
							}}
							onClick={() => _onClickItem(e)}
							key={`item-${e}-${i}`}>{
								e.child ??
								<Box display={'flex'}>
									{
										e.icon && <Box sx={{ color: theme.palette.primary.main }} mr={2}>{e.icon}</Box>
									}
									<Typography color={e.color}>{e.title}</Typography>
								</Box>
							}</MenuItem>
					))
				}
			</Menu>
		</Box>

	);
};

export default ButtonMore;
