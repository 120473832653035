export class RoutePath {
    static initial: string = '/'
    static login: string = '/login'

    static card: string = '/card'
    static cardCreate: string = '/card/edit'
    static cardUpdate: string = '/card/edit/:id'

    static menu: string = '/menu'
    static menuCreate: string = '/menu/edit'
    static menuUpdate: string = '/menu/edit/:id'

    static article: string = '/article'
    static articleCreate: string = '/article/edit'
    static articleUpdate: string = '/article/edit/:id'

    static cardCategory: string = '/card-category'
    static cardCategoryCreate: string = '/card-category/edit'
    static cardCategoryUpdate: string = '/card-category/edit/:id'

    static user: string = '/user'

    static order: string = '/order'
    static orderDetail: string = '/order/:id'

    static bank: string = '/bank'
    static transaction: string = '/transaction'
    static paymentMethod: string = '/payment-method'
    static paymentMethodCreate: string = '/payment-method/edit'
    static paymentMethodEdit: string = '/payment-method/edit/:id'

}