
import { Alert, Box, Dialog, Snackbar } from "@mui/material";
import { Outlet } from "react-router-dom";
import { RootProvider, useRootProvider } from "./provider";
import { useMyTheme } from "../../theme/use-my-theme";

function RootPage() {
    return (
        <RootProvider>
            <View />
        </RootProvider>
    );
}

function View() {
    const provider = useRootProvider()
const theme = useMyTheme()

    return (
        <Box bgcolor={theme.palette.background.default} minHeight={'100vh'}>
            <Outlet />
            <Snackbar
                sx={{
                    mt: 6
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={!!provider.msg}
                autoHideDuration={3000}
                onClose={() => provider.setMsg(undefined)}>
                <Alert severity="info">{provider.msg}</Alert>
            </Snackbar>
            <Dialog
                open={Boolean(provider.dialog)}
                onClose={() => provider.setDialog(undefined)}
            >
                {provider.dialog}
            </Dialog>
        </Box>
    );
}

export default RootPage
