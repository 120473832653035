import { Constants } from "../common/constant/constants"
import { UserModel } from "../models/user-model"

export const useUserManager = () => {
    function put(user?: UserModel): void {
        localStorage.setItem(Constants.user, JSON.stringify(user))
    }
    function get(): UserModel | null {
        const value = localStorage.getItem(Constants.user)
        if (!value) return null
        return UserModel.fromJson(JSON.parse(value))
    }
    function clear(): void {
        localStorage.removeItem(Constants.user)
    }

    return {
        put,
        get,
        clear,
    }
}