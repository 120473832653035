import { AccountCircleRounded, LogoutRounded } from "@mui/icons-material";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserManager } from "../../../../manager/user-manager";
import AppDialog from "../../../dialog/app-dialog";
import { RoutePath } from "../../../router/route-path";
import { OptionModel } from "../../../widgets/button-more";
import { useRootProvider } from "../../root/provider";

interface UserButtonType {

}

const options = [
	OptionModel.from({ id: 1, title: 'Profile', icon: <AccountCircleRounded /> }),
	OptionModel.from({ id: 2, title: 'Logout', icon: <LogoutRounded sx={{ color: 'red' }} /> }),
]

const UserButton = (props: UserButtonType) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const navigate = useNavigate()
	const rootProvider = useRootProvider()
	const userManager = useUserManager()
	const user = userManager.get()


	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	}


	function _onCloseLogout() {
		userManager.clear()
		navigate(RoutePath.login, { replace: true })
	}

	function _onClickItem(e: OptionModel): void {
		setAnchorEl(null)
		if (e.id === 1) {
			return navigate(RoutePath.card)
		}
		if (e.id === 2) {
			return rootProvider.setDialog(<AppDialog content="Are you sure want to logout?" onOk={_onCloseLogout} />)
		}
	}


	return (
		<Box>
			<Button
				variant="text"
				onClick={handleClick}
			>
				<AccountCircleRounded />
				{
					user &&
					<Typography
					
						sx={{
							ml: 2
						}}
					>{user.name}</Typography>
				}
			</Button>
			<Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
				{
					options.map((e) => (
						<MenuItem key={e.id} onClick={() => _onClickItem(e)}>
							{e.icon}
							<Typography sx={{ ml: 2 }}>{e.title}</Typography>
						</MenuItem>
					))
				}

			</Menu>
		</Box>
	);
};

export default UserButton;
