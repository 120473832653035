import { Box, Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useRootProvider } from "../layout/root/provider";

interface AppDialogType {
    title?: string
    content?: string
    onOk?: () => Promise<void> | Promise<boolean> | boolean | undefined | void
}

const AppDialog = (props: AppDialogType) => {
    const rootProvider = useRootProvider()

    function onClose() {
        rootProvider.setDialog(undefined)
    }

    async function onConfirm() {
        if (props.onOk) {
            const result = await props.onOk()
            if (result) return
        }
        onClose()
    }

    return (
        <Box>
            <DialogTitle >{props.title ?? 'Notification'}</DialogTitle>
            <DialogContent>
                <DialogContentText>{props.content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onConfirm} variant="contained">
                    Agree
                </Button>
            </DialogActions>
        </Box>
    );
}

export default AppDialog