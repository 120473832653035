import { UserRole } from "../enum/user-role"

export class UserModel {
   id?: string
   created?: string
   updated?: string
   deleted?: any
   name?: string
   username?: string
   password?: string
   email?: string
   token?: string
   phone?: any
   role?: UserRole

    static fromJson(json: UserModel): UserModel {
        const data = Object.assign(new UserModel(), json)

        return data
    }
   
}