import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import RootPage from './common/layout/root';
import { RootProvider } from './common/layout/root/provider';
import { RoutePath } from './common/router/route-path';
import { AuthProvider } from './pages/auth/auth-provider';
import ResponsiveDrawer from './common/layout/drawer/responsive-drawer';

const HomePage = lazy(() => import('./pages/home'))
const ArticlePage = lazy(() => import('./pages/article'))
const ArticleEditorPage = lazy(() => import('./pages/article/article-editor'))
const OrderPage = lazy(() => import('./pages/orders'))
const OrderDetailPage = lazy(() => import('./pages/orders/order-detail'))
const BankPage = lazy(() => import('./pages/bank'))
const CardPage = lazy(() => import('./pages/card'))
const PaymentMethodPage = lazy(() => import('./pages/payment-method'))
const PaymentMethodEditorPage = lazy(() => import('./pages/payment-method/payment-method-editor'))
const CardEditorPage = lazy(() => import('./pages/card/card-editor'))
const MenuPage = lazy(() => import('./pages/menu'))
const MenuEditorPage = lazy(() => import('./pages/menu/menu-editor'))
const UserPage = lazy(() => import('./pages/user'))
const CardCategoryPage = lazy(() => import('./pages/card-category'))
const CardCategoryEditorPage = lazy(() => import('./pages/card-category/card-category-editor'))
const LoginPage = lazy(() => import('./pages/auth/login'))

const theme = createTheme({
  palette: {
    background: {
      default: '#f6f8fc'
    }
  }
})

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={theme}>
        <RootProvider>
          <BrowserRouter>
            <Routes>
              <Route element={<RootPage />}>
                <Route element={<AuthProvider />}>
                  <Route element={<ResponsiveDrawer />}>
                    <Route path={RoutePath.initial} element={<Suspense><HomePage /></Suspense>} />
                    <Route path={RoutePath.card} element={<Suspense><CardPage /></Suspense>} />
                    <Route path={RoutePath.cardCreate} element={<Suspense><CardEditorPage /></Suspense>} />
                    <Route path={RoutePath.cardUpdate} element={<Suspense><CardEditorPage /></Suspense>} />
                    <Route path={RoutePath.cardCategory} element={<Suspense><CardCategoryPage /></Suspense>} />
                    <Route path={RoutePath.cardCategoryCreate} element={<Suspense><CardCategoryEditorPage /></Suspense>} />
                    <Route path={RoutePath.cardCategoryUpdate} element={<Suspense><CardCategoryEditorPage /></Suspense>} />
                    <Route path={RoutePath.menu} element={<Suspense><MenuPage /></Suspense>} />
                    <Route path={RoutePath.menuCreate} element={<Suspense><MenuEditorPage /></Suspense>} />
                    <Route path={RoutePath.menuUpdate} element={<Suspense><MenuEditorPage /></Suspense>} />
                    <Route path={RoutePath.user} element={<Suspense><UserPage /></Suspense>} />
                    <Route path={RoutePath.order} element={<Suspense><OrderPage /></Suspense>} />
                    <Route path={RoutePath.orderDetail} element={<Suspense><OrderDetailPage /></Suspense>} />
                    <Route path={RoutePath.bank} element={<Suspense><BankPage /></Suspense>} />
                    <Route path={RoutePath.paymentMethod} element={<Suspense><PaymentMethodPage /></Suspense>} />
                    <Route path={RoutePath.paymentMethodCreate} element={<Suspense><PaymentMethodEditorPage /></Suspense>} />
                    <Route path={RoutePath.paymentMethodEdit} element={<Suspense><PaymentMethodEditorPage /></Suspense>} />
                    <Route path={RoutePath.article} element={<Suspense><ArticlePage /></Suspense>} />
                    <Route path={RoutePath.articleCreate} element={<Suspense><ArticleEditorPage /></Suspense>} />
                    <Route path={RoutePath.articleUpdate} element={<Suspense><ArticleEditorPage /></Suspense>} />
                  </Route>
                </Route>
                <Route path={RoutePath.login} element={<Suspense><LoginPage /></Suspense>} />
                <Route path={'*'} element={<Suspense><LoginPage /></Suspense>} />
              </Route>
            </Routes>
          </BrowserRouter>
        </RootProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
