
import { ReactElement, ReactNode, createContext, useContext, useState } from "react";


const RootContext = createContext<RootType>(null!)

interface RootType {
    msg: string | undefined
    setMsg: (msg: string | undefined) => void
    dialog: ReactNode | undefined
    setDialog: (dialog: ReactNode | undefined) => void
    setItem: (key: string, data: any) => void
    getItem: (key: any) => any
    clear: (key?: string) => any
}

export function useRootProvider() {
    return useContext(RootContext)
}
let store: any = {}

export function RootProvider({ children }: { children: ReactElement }) {
    const [msg, setMsg] = useState<string | undefined>()
    const [dialog, setDialog] = useState<ReactNode | undefined>();

    function setItem(key: string, data: any): void {
        store[key] = data
    }

    function getItem(key: any): any {
        return store[key]
    }

    function clear(key?: string) {
        if (key) {
            return store[key] = undefined
        }
        store = {}
    }



    const value = {
        msg,
        setMsg,
        dialog,
        setDialog,
        setItem,
        getItem,
        clear,
    }
    return (
        <RootContext.Provider value={value}>
            {children}
        </RootContext.Provider>
    )
}
