export class DrawerModel {
    id?: number
    level?: number
    title?: string
    path?: string
    items?: Array<DrawerModel>

    static fromJson(json: DrawerModel): DrawerModel {
        const data = Object.assign(new DrawerModel(), json)
        if (json.items) {
            data.items = json.items.map(DrawerModel.fromJson)
        }
        return data
    }
}