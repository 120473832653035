import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from "@mui/icons-material";
import { Box, ListItemButton, ListItemText, alpha } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DrawerModel } from "../../../models/drawer-model";
import { useMyTheme } from "../../theme/use-my-theme";

interface ItemDrawerType {
	onCloseDrawer?: VoidFunction
	item?: DrawerModel
}

const ItemDrawer = (props: ItemDrawerType) => {
	const [expanded, setExpanded] = useState<boolean>(false)
	const navigate = useNavigate()
	const location = useLocation()
	const theme = useMyTheme()

	function onToggle() {
		if (props.item?.items?.length) {
			return setExpanded(!expanded)
		}
		if (!props.item?.path) return
		props.onCloseDrawer && props.onCloseDrawer()
		navigate(props.item?.path ?? '')
	}

	return (
		<Box >
			<ListItemButton
				sx={{
					pl: (props.item?.level ?? 0) * 3 + 2,
					color: location.pathname === props.item?.path ? theme.palette.primary.main : '',
					backgroundColor: location.pathname === props.item?.path ? alpha(theme.palette.primary.main, 0.2) : '',
					borderTopRightRadius: 100,
					borderBottomRightRadius: 100,
				}}
				onClick={onToggle}>
				<ListItemText>
					{
						location.pathname === props.item?.path
							? <b>{props.item?.title}</b>
							: props.item?.title
					}
				</ListItemText>
				{
					props.item?.items?.length
						? expanded
							? <KeyboardArrowUpRounded />
							: <KeyboardArrowDownRounded />
						: <Box />
				}
			</ListItemButton>
			{
				expanded && (
					props.item?.items?.map((e) => (
						<ItemDrawer item={e} key={e.title} />
					))
				)
			}
		</Box>

	);
}

export default ItemDrawer